import { db, auth, schoolYearsCollection, logsCollection, FieldValue } from '@/firebase'
import { getCurrentDateTime } from '@/services/time'
import { format } from 'date-fns'

export default {
  namespaced: true,
  state: {
    schoolYears: [],
    activeSchoolYear: null,
  },
  getters: {
    getSchoolYears: state => {
      return state.schoolYears
    },
    getActiveSchoolYear: state => {
      return state.activeSchoolYear
    }
  },
  actions: {
    async createSchoolYear({ commit, dispatch }, { docId, schoolYear, schoolYearDays }) {
      commit('loading/setLoading', 'createSchoolYear', { root: true })
      await schoolYearsCollection.doc(docId).set(schoolYear)
      dispatch('createSchoolYearDays', { docId, schoolYearDays})
      commit('loading/setLoading', false, { root: true })
    },
    async createSchoolYearDays({ commit, dispatch }, { docId, schoolYearDays }) {
      commit('loading/setLoading', 'createSchoolYearDays', { root: true })
      let batch = db.batch()
      schoolYearDays.forEach(week => {
        week.forEach(day => {
          const dayDocId = format(day.date, 'yyyy-MM-dd')
          const dayRef = schoolYearsCollection
                        .doc(docId)
                        .collection('days')
                        .doc(dayDocId)

          batch.set(dayRef, { date: day.date })
        })
      })
      batch.commit().then(() => {
        dispatch('createSchoolYearDaysSlots', { docId, schoolYearDays})
        commit('loading/setLoading', false, { root: true })
      });
    },
    async createSchoolYearDaysSlots({ commit, dispatch }, { docId, schoolYearDays }) {
      commit('loading/setLoading', 'createSchoolYearDaysSlots', { root: true })
      let batchs = [db.batch()]
      let batchsIndex = 0
      let count = 0
      schoolYearDays.forEach(week => {
        week.forEach(day => {
          const dayDocId = format(day.date, 'yyyy-MM-dd')
          const slotsCollectionRef = schoolYearsCollection
                              .doc(docId)
                              .collection('days')
                              .doc(dayDocId)
                              .collection('slots')

          day.slots.forEach(slot => {
            if (count > 499) {
              count = 0
              batchsIndex++
              batchs.push(db.batch())
            } else {
              count++
            }
            const slotRef = slotsCollectionRef.doc(slot.slug)
            batchs[batchsIndex].set(slotRef, slot)
          })
        })
      })

      Promise.all(batchs.map(batch => batch.commit())).then(() => {
        dispatch('fetchSchoolYears')
        commit('loading/setLoading', false, { root: true })
      })
    },
    async updateSchoolYearState({ commit, dispatch }, { uid, state }) {
      commit('loading/setLoading', 'updateSchoolYearState', { root: true })
      if (state === true) {
        let batch = db.batch()
        schoolYearsCollection.where('active', '==', true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            batch.update(schoolYearsCollection.doc(doc.id), { active: false })
          })
          batch.update(schoolYearsCollection.doc(uid), { active: true })

          batch.commit().then(() => {
            dispatch('fetchSchoolYears')
            commit('loading/setLoading', false, { root: true })
          });
        })
      } else {
        await schoolYearsCollection.doc(uid).update({ active: state })
        dispatch('fetchSchoolYears')
        commit('loading/setLoading', false, { root: true })
      }
      
    },
    async fetchSchoolYears({ commit }) {
      commit('loading/setLoading', 'fetchSchoolYears', { root: true })
      let schoolYears = []
      schoolYearsCollection.get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            schoolYears.push({
              uid: doc.id,
              ...doc.data()
            })
          })
          commit('setSchoolYears', schoolYears)
          commit('loading/setLoading', false, { root: true })
        })
    },
    async fetchActiveSchoolYear({ commit }) {
      commit('loading/setLoading', 'fetchActiveSchoolYear', { root: true })
      commit('setActiveSchoolYear', null)
      let schoolYear
      schoolYearsCollection.where('active', '==', true)
        .get()
        .then(querySnapshotSchoolYear => {
          if (querySnapshotSchoolYear.docs.length > 0) {
            schoolYear = {
              uid: querySnapshotSchoolYear.docs[0].id,
              ...querySnapshotSchoolYear.docs[0].data(),
              days: []
            }
            
            let promiseYear = new Promise(resolveYear => {

              schoolYearsCollection
                .doc(schoolYear.uid)
                .collection('days')
                .get()
                .then(querySnapshotDays => {
                  let promisesDays = []
                  querySnapshotDays.forEach(day => {
                    promisesDays.push(new Promise(resolveDay => {
                      let formattedDay = {
                        uid: day.id,
                        ...day.data(),
                        slots: []
                      }
                      
                      schoolYearsCollection
                        .doc(schoolYear.uid)
                        .collection('days')
                        .doc(formattedDay.uid)
                        .collection('slots')
                        .get()
                        .then(querySnapshotSlots => {
                          querySnapshotSlots.forEach(slot => {
                            formattedDay.slots.push({
                              ...slot.data()
                            })
                          })
                          resolveDay(formattedDay)
                        })
                    }))
                  })

                  Promise.all(promisesDays).then(values => {
                    resolveYear(values)
                  })
              })
            })
                  
            promiseYear.then(values => {
              schoolYear.days = values.sort((a,b) => {
                return a.date.seconds - b.date.seconds
              })
              commit('setActiveSchoolYear', schoolYear)
              commit('loading/setLoading', false, { root: true })
            })
          } else {
            commit('loading/setLoading', false, { root: true })
          }
        })
    },
    async enrollKid({ commit, dispatch, state }, { kidUid, days }) {
      commit('loading/setLoading', 'enrollKid', { root: true })
      
      getCurrentDateTime().then(res => {
        const currentDateTime = new Date(res)
        
        let batchs = [db.batch()]
        let batchsIndex = 0
        let count = 0
  
        const schoolYearDoc = schoolYearsCollection.doc(state.activeSchoolYear.uid)
        days.forEach(day => {
          const dayDoc = schoolYearDoc.collection('days').doc(day.uid)
          day.slots.forEach(slot => {
            if (count > 490) {
              count = 0
              batchsIndex++
              batchs.push(db.batch())
            } else {
              count += 2
            }
            
            const slotDoc = dayDoc.collection('slots').doc(slot.slug)
            if (slot.value) {
              batchs[batchsIndex].update(slotDoc, "kids", FieldValue.arrayUnion(kidUid))
            } else {
              batchs[batchsIndex].update(slotDoc, "kids", FieldValue.arrayRemove(kidUid))
            }
            const logDoc = logsCollection.doc()
            const user = auth.currentUser
            batchs[batchsIndex].set(logDoc, {
              year: state.activeSchoolYear.uid,
              day: day.uid,
              slot: slot.slug,
              date: currentDateTime,
              kid: kidUid,
              user: user.uid,
              status: slot.value
            })
          })
        })
  
        Promise.all(batchs.map(batch => batch.commit())).then(() => {
          dispatch('fetchActiveSchoolYear')
          commit('loading/setLoading', false, { root: true })
        })
      })
    }
  },
  mutations: {
    setSchoolYears(state, val) {
      state.schoolYears = val
    },
    setActiveSchoolYear(state, val) {
      state.activeSchoolYear = val
    }
  },
}
