import { auth, kidsCollection } from '@/firebase'

export default {
  namespaced: true,
  state: {
    kids: []
  },
  getters: {
    getKids: state => {
      return state.kids
    }
  },
  actions: {
    async createKid({ commit, dispatch }, { form, dispatchName }) {
      commit('loading/setLoading', 'createKid', { root: true })
      const user = auth.currentUser
      const kid = {
        ...form,
        parent: [user.uid],
        verified: false
      }

      await kidsCollection.doc().set(kid)

      dispatch(dispatchName)
      commit('loading/setLoading', false, { root: true })
    },
    async updateKid({ commit, dispatch }, { data, dispatchName }) {
      commit('loading/setLoading', 'updateKid', { root: true })
      await kidsCollection.doc(data.uid).update(data.kid)

      dispatch(dispatchName)
      commit('loading/setLoading', false, { root: true })
    },
    async deleteKid({ commit, dispatch }, { uid, dispatchName }) {
      commit('loading/setLoading', 'deleteKid', { root: true })
      await kidsCollection.doc(uid).delete()

      dispatch(dispatchName)
      commit('loading/setLoading', false, { root: true })
    },
    async fetchUserKids({ commit }) {
      commit('loading/setLoading', 'fetchUserKids', { root: true })
      const user = auth.currentUser
      kidsCollection.where('parent', 'array-contains', user.uid)
        .get()
        .then((querySnapshot) => {
          let kids = []
          querySnapshot.forEach((doc) => {
            kids.push({
              uid: doc.id,
              ...doc.data()
            })
          })

          const orderedKids = kids.sort((a, b) => {
            if (a.lastname > b.lastname) {
              return 1
            } else if (a.lastname < b.lastname) {
              return -1
            } else {
              if (a.firstname > b.firstname) {
                return 1
              } else if (a.firstname < b.firstname) {
                return -1
              } else {
                return 0
              }    
            }
          })

          commit('setKids', orderedKids)
          commit('loading/setLoading', false, { root: true })
        })
    },
    async fetchAllKids({ commit }) {
      commit('loading/setLoading', 'fetchAllKids', { root: true })
      kidsCollection
        .get()
        .then((querySnapshot) => {
          let kids = []
          // let promises = []
          // let parents = []
          querySnapshot.forEach(doc => {
            let kid = {
              uid: doc.id,
              ...doc.data(),
            }
            // kid.parent.forEach(parent => {
            //   const foundParent = parents.find(el => el === parent)
            //   if (foundParent) {
            //     return
            //   } else {
            //     parents.push(parent)
            //   }
            //   promises.push(new Promise(resolve => {
            //     usersCollection.doc(parent).get().then(response => {
            //       resolve({
            //         ...response.data(),
            //         uid: parent
            //       })
            //     })
            //   }))
            // })
            kids.push(kid)
          })

          // Promise.all(promises).then((values) => {
            // kids.forEach(kid => {
            //   kid.parents = []
            //   kid.parent.forEach(parent => {
            //     kid.parents.push(values.find(el => el.uid === parent))
            //   })
            // })

            const orderedKids = kids.sort((a, b) => {
              if (a.lastname > b.lastname) {
                return 1
              } else if (a.lastname < b.lastname) {
                return -1
              } else {
                if (a.firstname > b.firstname) {
                  return 1
                } else if (a.firstname < b.firstname) {
                  return -1
                } else {
                  return 0
                }    
              }
            })
  
            commit('setKids', orderedKids)
            commit('loading/setLoading', false, { root: true })
          // })
        })
    },
  },
  mutations: {
    setKids(state, val) {
      state.kids = val
    }
  },
}
